import React from "react";
import Img from "gatsby-image";
import { Link } from "gatsby";

const Info = ({ data, title }) => {
  return (
    <div className="work section" id="info">
      <div className="container">
        <div className="section-head">
          <h2 className="text-center">{title}</h2>
        </div>
        <ul className="work-list">
          {data.map((item, i) => {
            const { url, image, siteName } = item.node;
            return (
              <li key={i} className="item">
                <div className="inner">
                  <Link to={url}>
                    <Img
                      fluid={image.fluid}
                      objectFit="cover"
                      objectPosition="50% 50%"
                    />
                    <span className="name">{siteName}</span>
                  </Link>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default Info;
