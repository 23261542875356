import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

import Banner from "../components/banner";
import Features from "../components/features";
import About from "../components/about";
import Info from "../components/info";
import Testimonial from "../components/testimonial";

const IndexPage = ({
  data: { seo, home, banner, features, about, infoPages, testimonials },
}) => {
  return (
    <Layout>
      <SEO
        title={seo.seoTitle}
        keywords={[`Casa Amiga Pamplona`, `Madre de dia`, `Guarderia Pamplona`]}
      />
      <Banner data={banner}></Banner>
      <Features data={features.edges} title={home.featuresTitle}></Features>
      <About data={about}></About>
      <Info data={infoPages.edges} title={home.infoTitle}></Info>
      <Testimonial
        data={testimonials.edges}
        title={home.titlePeople}
      ></Testimonial>
    </Layout>
  );
};
export default IndexPage;

export const pageQuery = graphql`
  query Main {
    seo: contentfulSiteInformation {
      seoTitle
    }
    home: contentfulPortadaWeb {
      featuresTitle
      infoTitle
      photoTitle
      btnPhotos
      blogTitle
      btnBlog
      titlePeople
    }
    banner: contentfulPortadaWeb {
      title
      icon
      subtitle
      cta
      bannerImage {
        fluid(maxWidth: 1500) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    features: allContentfulDestacados {
      edges {
        node {
          title
          info: description {
            description
          }
          icon
        }
      }
    }
    about: contentfulPortadaWeb {
      sideImage {
        file {
          url
        }
        fluid(maxWidth: 350) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
      firstText
      secondaryTitle
      mainContent {
        childMarkdownRemark {
          html
        }
      }
      firstLabel
      firstLabelContent
      secondLabel
      instagram
    }
    infoPages: allContentfulPaginasDestacadas {
      edges {
        node {
          siteName
          url
          image {
            fluid(maxWidth: 400) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
        }
      }
    }
    testimonials: allContentfulTestimonials {
      edges {
        node {
          name
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;
