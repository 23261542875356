import React from "react";
import Slider from "react-slick";

const settings = {
  dots: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  pauseOnHover: true,
  autoplaySpeed: 6000,
};

const Testimonial = ({ data, title }) => {
  return (
    <div className="slider-section section testimonials" id="Testimonials">
      <div className="container">
        <div className="section-head text-center">
          <h2>{title}</h2>
        </div>
        <Slider {...settings}>
          {data.map((item, i) => {
            const { description, name } = item.node;
            return (
              <div key={i} className="testimonials-item">
                <div className="testi-inner">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: description.childMarkdownRemark.html,
                    }}
                  />
                  <span className="sub-name">{name}</span>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default Testimonial;
