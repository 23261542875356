import React from "react";
import Img from "gatsby-image";
import { Link } from "gatsby";

const Banner = ({ data }) => {
  const { bannerImage, title, icon, subtitle, cta } = data;
  return (
    <div className="banner">
      <Img
        fluid={bannerImage.fluid}
        objectFit="cover"
        objectPosition="50% 50%"
        fadeIn={false}
        loading="eager"
      />
      <div className="container">
        <div className="banner-details">
          <h1>{title}</h1>
          <ul className="sub-data">
            <li>
              <span className={icon}></span> {subtitle}
            </li>
          </ul>
          <div className="cta see-more">
            <Link to="/#contact">
              <span>{cta}</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
