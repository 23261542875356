import React from "react";

const Features = ({ data: features, title }) => {
  return (
    <div className="feature section" id="feature">
      <div className="container">
        <div className="section-head">
          <h2>{title}</h2>
        </div>
        <div className="row">
          {features.map((feature, i) => {
            const { title, info, icon } = feature.node;
            return (
              <div key={i} className="col-feature col-md-4 mb-3">
                <div className="feature-main">
                  <div>
                    <span className={`feature-icon color-icon ${icon}`}></span>
                    <h3>{title}</h3>
                  </div>
                  <div>{info.description}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Features;
