import React from "react";
import Img from "gatsby-image";

const About = ({
  data: {
    sideImage,
    firstText,
    secondaryTitle,
    mainContent,
    firstLabelContent,
    instagram,
    firstLabel,
    secondLabel,
  },
}) => {
  return (
    <div className="about section" id="About">
      <div className="container">
        <div className="about-main row">
          <div className="left col-md-5 col-lg-4 mb-3">
            <Img
              fluid={sideImage.fluid}
              objectFit="cover"
              objectPosition="top center"
            />
          </div>
          <div className="left col-md-7 col-lg-8">
            <div className="about-details">
              <span className="name">{firstText}</span>
              <h2 className="sub-position">{secondaryTitle}</h2>
              <div
                className="main-home-content"
                dangerouslySetInnerHTML={{
                  __html: mainContent.childMarkdownRemark.html,
                }}
              />
              <ul className="details">
                <li>
                  <strong>{firstLabel}</strong>
                  <p>{firstLabelContent}</p>
                </li>
                <li className="socials">
                  <strong>{secondLabel}</strong>
                  <a
                    className="fab fa-instagram"
                    href={instagram}
                    target="_blank"
                    rel="noopener noreferrer"
                  ></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
